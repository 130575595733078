// Old Default
export const list = '/list'
export const store = '/store'
export const update = '/update'
export const toggleStatus = '/toggle-status'
export const destroy = '/destroy'
// Country
export const countryList = 'country/list'
export const countryStore = 'country/store'
export const countryUpdate = 'country/update'
export const countryToggleStatus = 'country/toggle-status'
export const countryDestroy = 'country/destroy'
// Division
export const divisionList = 'division/list'
export const divisionStore = 'division/store'
export const divisionUpdate = 'division/update'
export const divisionToggleStatus = 'division/toggle-status'
export const divisionDestroy = 'division/destroy'
// District
export const districtList = '/district/list'
export const districtStore = '/district/store'
export const districtUpdate = '/district/update'
export const districtToggleStatus = '/district/toggle-status'
export const districtDestroy = '/district/destroy'
// upazila
export const upazillaList = '/upazilla/list'
export const upazillaStore = '/upazilla/store'
export const upazillaUpdate = '/upazilla/update'
export const upazillaToggleStatus = '/upazilla/toggle-status'
export const upazillaDestroy = '/upazilla/destroy'
//  union
export const UnionList = 'union/list'
export const UnionStore = 'union/store'
export const UnionUpdate = 'union/update'
export const UnionToggleStatus = 'union/toggle-status'
export const UnionDelete = 'union/delete'
// Organization List for Checkbox
export const organizationList = 'common/org-list'
export const componentListDropdown = 'common/component-list'
//  organizaion profile
export const orgProfileList = 'master-org-profile/list'
export const orgProfileStore = 'master-org-profile/store'
export const orgProfileUpdate = 'master-org-profile/update'
export const orgProfileToggleStatus = 'master-org-profile/toggle-status'
export const orgProfileDelete = 'master-org-profile/destroy'
//  Component
export const componentList = 'component/list'
export const componentDetailsList = 'component/details/list'
export const componentStore = 'component/store'
export const componentUpdate = 'component/update'
export const componentToggleStatus = 'component/toggle-status'
export const componentDelete = 'component/destroy'
//  Service
export const serviceList = 'service/list'
export const serviceDetailsList = 'service/details/list'
export const serviceStore = 'service/store'
export const serviceUpdate = 'service/update'
export const serviceToggleStatus = 'service/toggle-status'
export const serviceDelete = 'service/destroy'
//  Office Type
export const officeTypeList = 'master-office-types/list'
export const officeTypeStore = 'master-office-types/store'
export const officeTypeUpdate = 'master-office-types/update'
export const officeTypeToggleStatus = 'master-office-types/toggle-status'
export const officeTypeDelete = 'master-office-types/delete'
//  menu
export const menuList = 'master-menu/list'
export const menuDetailsList = 'master-menu/details/list'
export const menuStore = 'master-menu/store'
export const menuUpdate = 'master-menu/update'
export const menuToggleStatus = 'master-menu/toggle-status'
export const menuDelete = 'master-menu/delete'

//  menu
export const ministryDashboardMenuList = 'ministry-dashboard-menu/list'
export const ministryDashboardMenuDetailsList = 'ministry-dashboard-menu/details/list'
export const ministryDashboardMenuStore = 'ministry-dashboard-menu/store'
export const ministryDashboardMenuUpdate = 'ministry-dashboard-menu/update'
export const ministryDashboardMenuToggleStatus = 'ministry-dashboard-menu/toggle-status'
export const ministryDashboardMenuDelete = 'ministry-dashboard-menu/delete'

//  Office
export const officeList = 'master-office-types/list'
export const officeStore = 'master-office-types/store'
export const officeUpdate = 'master-office-types/update'
export const officeToggleStatus = 'master-office-types/toggle-status'
export const officeDelete = 'master-office-types/destroy'
//  Module
export const moduleList = 'module/list'
export const moduleDetailsList = 'module/details/list'
export const moduleStore = 'module/store'
export const moduleUpdate = 'module/update'
export const moduleToggleStatus = 'module/toggle-status'
export const moduleDelete = 'module/destroy'
//  office entry
export const officeEntryList = 'master-office/list'
export const officeEntryStore = 'master-office/store'
export const officeEntryUpdate = 'master-office/update'
export const officeEntryToggleStatus = 'master-office/toggle-status'
export const officeEntryDelete = 'master-office/destroy'
export const officeEntryParentOffice = 'master-office/parent-office'
// Bank
export const bankList = 'master-bank/list'
export const bankStore = 'master-bank/store'
export const bankUpdate = 'master-bank/update'
export const bankToggleStatus = 'master-bank/toggle-status'
export const bankDelete = 'master-bank/destroy'
// Branch
export const branchList = 'master-branch/list'
export const branchStore = 'master-branch/store'
export const branchUpdate = 'master-branch/update'
export const branchToggleStatus = 'master-branch/toggle-status'
export const branchDelete = 'master-bank/destroy'
export const branchExcelStore = 'master-branch/excel-store'

// dialogue-info
export const dialogueInfoList = 'master-dialogue-info-settings/list'
export const dialogueInfoStore = 'master-dialogue-info-settings/store'
export const dialogueInfoUpdate = 'master-dialogue-info-settings/update'
export const dialogueInfoToggleStatus = 'master-dialogue-info-settings/toggle-status'
export const dialogueInfoDelete = 'master-dialogue-info-settings/destroy'
// fiscal Year
export const fiscalYearList = 'master-fiscal-year/list'
export const fiscalYearStore = 'master-fiscal-year/store'
export const fiscalYearUpdate = 'master-fiscal-year/update'
export const fiscalYearToggleStatus = 'master-fiscal-year/toggle-status'
export const fiscalYearDelete = 'master-fiscal-year/destroy'
//  paurashoba
export const paurashobaList = 'master-pauroshoba/list'
export const paurashobaStore = 'master-pauroshoba/store'
export const paurashobaUpdate = 'master-pauroshoba/update'
export const paurashobaToggleStatus = 'master-pauroshoba/toggle-status'
export const paurashobaDelete = 'master-pauroshoba/delete'
//  cityCorporation
export const cityCorporationList = 'master-city-corporation/list'
export const cityCorporationStore = 'master-city-corporation/store'
export const cityCorporationUpdate = 'master-city-corporation/update'
export const cityCorporationToggleStatus = 'master-city-corporation/toggle-status'
export const cityCorporationDelete = 'master-city-corporation/delete'
//  masterWards
export const wardList = 'master-wards/list'
export const wardStore = 'master-wards/store'
export const wardUpdate = 'master-wards/update'
export const wardToggleStatus = 'master-wards/toggle-status'
export const wardDelete = 'master-wards/delete'
