<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="10" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                <ValidationProvider name="Component name" vid="component_name" rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="component_name"
                    slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                    {{ $t('org_pro.component_name') }} <span class="text-danger">*</span>
                  </template>
                    <b-form-input
                      id="component_name"
                      v-model="component.component_name"
                      placeholder="Enter Component Name"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Component name bn" vid="component_name_bn" rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="component_name_bn"
                    slot-scope="{ valid, errors }"
                  >
                   <template v-slot:label>
                     {{ $t('org_pro.component_name_bn') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="component_name_bn"
                      v-model="component.component_name_bn"
                      placeholder="Enter Component Name"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Description" vid="description">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('org_pro.component_description')"
                    label-for="description"
                    slot-scope="{ valid, errors }"
                  >
                    <b-form-input
                      id="description"
                      v-model="component.description"
                      placeholder="Enter Description"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Description bn" vid="description_bn">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('org_pro.component_description_bn')"
                    label-for="description_bn"
                    slot-scope="{ valid, errors }"
                  >
                    <b-form-input
                      id="description_bn"
                      v-model="component.description_bn"
                      placeholder="Enter Description"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('org_pro.sorting_order')"
                  label-for="sorting_order"
                >
                  <template v-slot:label>
                    {{ $t('org_pro.sorting_order')}} <span class="text-danger">*</span>
                  </template>
                  <input @change="checkSortOrder(component,$event)" :id="component.id" type="text" :value="component.sorting_order" class="form-control"/>
                </b-form-group>
                <ValidationProvider name="Organization list" vid="org_id" rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('org_pro.organization_list')"
                    label-for="organization_list"
                    slot-scope="{ valid, errors }"
                  >
                    <b-form-checkbox-group
                            v-model="component.org_id"
                            :options="organizationList"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-checkbox-group>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <div class="row">
                  <div class="col-sm-3"></div>
                  <div class="col text-right">
                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                    &nbsp;
                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import OrderCheckModule from '@/Utils/common'
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { commonServiceBaseUrl } from '../../../../../config/api_config'
import { componentStore, componentUpdate } from '../../api/routes'

export default {
  name: 'FormLayout',
  props: ['id', 'organizatinData'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.getOrganizationlist()
    if (this.id) {
      this.component = this.getcomponentData()
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      component: {
        component_name: '',
        component_name_bn: '',
        description: '',
        description_bn: '',
        sorting_order: 0,
        org_id: []
      },
      organizationList: [],
      componentList: this.$store.state.commonObjCommonConfig.componentList
    }
  },
  computed: {
  },
  methods: {
    checkSortOrder: function (item, event) {
      item.old_serial = item.sorting_order
      item.sorting_order = event.target.value
      const returnData = OrderCheckModule.OrderCheck(item, this.componentList, this.isSave, 'Comp')
      this.componentList = returnData.moduleListData
      this.isSave = returnData.isSave
    },
    getcomponentData () {
      let tmp = this.$store.state.list.find(item => item.id === this.id)
      tmp = Object.assign({}, tmp, { org_id: tmp.org.map(dt => dt.id) })
      return JSON.parse(JSON.stringify(tmp))
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.component.id) {
        result = await RestApi.putData(commonServiceBaseUrl, `${componentUpdate}/${this.id}`, this.component)
      } else {
        result = await RestApi.postData(commonServiceBaseUrl, componentStore, this.component)
      }
      loadingState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.commit('mutateDropdownCommonConfig', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getOrganizationlist () {
      const tmp = this.$store.state.commonObj.organizationProfileList.map(item => {
        if (this.$i18n.locale === 'en') {
          return { text: item.abbreviation, value: item.value, status: item.status }
        } else {
          return { text: item.abbreviation_bn, value: item.value, status: item.status }
        }
      })
      this.organizationList = tmp.filter(item => item.status === 0)
    }
  }
}
</script>
