
<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('org_pro.component') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('org_pro.component_namel')"
                label-for="component_name"
                >
                <b-form-input
                    id="component_name"
                    v-model="search.component_name"
                    placeholder=""
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col lg="6" md="6" sm="12">
                <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
            </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('org_pro.component') + ' ' + $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="datas" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(component_name)="data">
                      <span class="capitalize">{{ data.item.component_name }}</span>
                    </template>
                    <template v-slot:cell(discription)="data">
                      <span class="capitalize">{{ data.item.discription }}</span>
                    </template>
                    <template v-slot:cell(org)="data">
                      {{ getSelectedOrgList(data.item.org) }}
                    </template>
                    <template v-slot:cell(sorting_order)="data">
                      <span class="capitalize">
                        <input @change="checkSortOrder(data.item,$event)"  type="number" :value="data.item.sorting_order"  style="width: 100px;margin:auto" class="form-control text-center"/>
                      </span>
                    </template>
                    <template v-slot:cell(status)="data">
                      <span class="badge" :class="data.item.status ? 'badge-danger' : 'badge-success'">{{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                        <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                        <a href="javascript:" class="btn_table_action table_action_status" title="Change Status" v-if="data.item.status === 0" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                        <a href="javascript:" class="btn_table_action table_action_toggle" title="Change Status" v-if="data.item.status === 1" @click="remove(data.item)"><i class="fas fa-toggle-off"></i></a>
                    </template>
                    <template #custom-foot="">
                      <b-tr>
                        <b-th colspan="4"></b-th>
                        <b-th class="text-center">
                          <button class="btn btn-primary btn-sm" v-on:click="SubmitData()">
                            <i class="fas fa-list"></i>Submit
                          </button>
                        </b-th>
                        <b-th colspan="2"></b-th>
                      </b-tr>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Form :id="editItemId" :key="editItemId" :organizatinData="organizations"/>
    </b-modal>
    <!-- <pre>{{datas}}</pre> -->
  </b-container>
</template>
<script>
import Form from './Form'
import RestApi, { commonServiceBaseUrl } from '@/config/api_config'
import { componentList, componentToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import OrderCheckModule from '@/Utils/common'

export default {
  mixins: [ModalBaseMasterList],
  components: { Form },
  data () {
    return {
      sl: 0,
      search: {
        component_name: ''
      },
      rows: [],
      organizations: [],
      datas: [],
      componentList: this.$store.state.commonObjCommonConfig.componentList,
      componentDetails: [],
      isSave: true
    }
  },
  computed: {
    listData: function () {
      const tmpData = this.$store.state.list
      return JSON.parse(JSON.stringify(tmpData))
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('org_pro.component_entry') : this.$t('org_pro.component') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('org_pro.component'), class: 'text-center' },
          { label: this.$t('org_pro.component_descriptionl'), class: 'text-center' },
          { label: this.$t('org_pro.organization'), class: 'text-center' },
          { label: this.$t('org_pro.sorting_order'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center w-10' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'component_name_bn' },
          { key: 'description_bn' },
          { key: 'org' },
          { key: 'sorting_order' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'component_name' },
          { key: 'description' },
          { key: 'org' },
          { key: 'sorting_order' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
  },
  async created () {
    this.loadData()
  },
  mounted () {
  },
  methods: {
    checkSortOrder: function (item, event) {
      item.old_serial = item.sorting_order
      item.sorting_order = event.target.value
      const returnData = OrderCheckModule.OrderCheck(item, this.componentList, this.isSave, 'Comp')
      this.componentList = returnData.moduleListData
      this.isSave = returnData.isSave
    },
    searchData () {
      this.loadData()
    },
    remove (item) {
      this.changeStatus(commonServiceBaseUrl, componentToggleStatus, item, 'common_config', 'componentList')
    },
    details (item) {
      this.componentDetails = item
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(commonServiceBaseUrl, componentList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', response.data.data)
          this.paginationData(response.data)
          const allData = this.$store.state.list
          this.datas = JSON.parse(JSON.stringify(allData))
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getSelectedOrgList (orgs) {
      const orgList = orgs.map(item => {
        return this.$i18n.locale === 'en' ? item.abbreviation : item.abbreviation_bn
      })
      if (orgList === null || orgList === undefined) {
        return ''
      }
      return orgList.join(', ')
    },
    async confirmData () {
      let result = null
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      result = await RestApi.postData(commonServiceBaseUrl, 'master-menu/change-serial-order/MasterComponent', this.datas)
      if (result.success) {
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        this.$store.commit('mutateDropdownCommonConfig', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: 'Data saved successfully',
          color: '#D6E09B'
        })
        this.loadData()
      }
    },
    async SubmitData () {
      this.$swal({
        title: this.$t('globalTrans.recommendentChangeMsg'),
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        focusConfirm: false
      }).then((resultSwal) => {
        if (resultSwal.isConfirmed) {
          this.confirmData()
        }
      })
    }
  }
}
</script>
